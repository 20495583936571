/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Link } from '../styled';

export const BlogPostsDataFragment = graphql`
  fragment BlogPostsData on Mdx {
    excerpt(pruneLength: 160)
    frontmatter {
      title
      slug
      description
      headerImage {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 215, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const BlogPost = ({
  post: {
    excerpt,
    frontmatter: { title, description, slug, headerImage },
  },
}) => (
  <Link to={slug}>
    <Box sx={{ borderRadius: 15, overflow: 'hidden' }}>
      {headerImage && <Img fluid={headerImage.childImageSharp.fluid} />}
    </Box>
    <Box as="h4" variant="header2Med" color="black" mt={20}>
      {title}
    </Box>
    <Box variant="body1" mt={20}>
      {description || excerpt}
    </Box>
  </Link>
);

BlogPost.propTypes = {
  post: PropTypes.shape({
    excerpt: PropTypes.string.isRequired,
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      headerImage: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

export default BlogPost;
