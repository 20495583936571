import PropTypes from 'prop-types';
import React from 'react';

import { Grid } from '../styled';
import BlogPost from './blog-post';

const BlogPostGrid = ({ posts, ...props }) => {
  return (
    <Grid
      gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
      gridColumnGap={30}
      gridRowGap={[60]}
      {...props}
    >
      {posts.map(({ node }) => (
        <BlogPost key={node.frontmatter.slug} post={node} />
      ))}
    </Grid>
  );
};

BlogPostGrid.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BlogPostGrid;
